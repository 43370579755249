<template>
  <div id="typeContract" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">TIPO DE CONTRATO</v-row>
      <v-container fluid class="addCategory">
        <!--PRUEBA-->
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="4" sm="5" class="marginCol">
            <v-row>
              <div class="form-group mt-9 mb-5">
                <label for="contract">Tipo de Contrato</label>
                <input
                  type="text"
                  name="contract"
                  id="contract"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="contract"
                  required
                  maxlength="60"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" class="mt-12">
            <button class="botonAmarillo mt-5" @click="validacion()">
              Guardar
            </button>
            <router-link to="/contract" id="backContract">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Contrato</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar el tipo de contrato?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Contrato</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
import Loader from "@/components/Loader";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      search: "",
      expiration: false,
      dialog: false,
      contract: "",
      errors: [],
      respuesta: "",
      advice: false,
      errorAlmacen: false,
      confirmation: false,
      error: false,
      show:false
    };
  },
  methods: {
    guardar() {
      this.advice = false;
      this.show=true;
      axios
        .post(Server + "/contratos", {
          tipoContrato: this.contract,
        },
          {
            headers: {
              Authorization: localStorage.token,
            },
          })
        .then((response) => {
          //console.log(response);
          this.contract = "";
          this.respuesta = "El tipo de contrato fue guardado con éxito";
          this.show=false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show=false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.contract = "";
            this.respuesta = "El tipo de contrato no fue almacenado";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (this.contract == "" || this.contract == " ") {
        this.errors.push("Se debe especificar el tipo de contrato.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    cancel() {
      this.advice = false;
      this.contract = "";
    },
    aux() {
      this.confirmation = false;
      this.contract = "";
      this.$router.push("/contract");
    },
  },
  created() {},
};
</script>